import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	withLatestFrom,
	distinctUntilChanged,
	filter,
	from,
	switchMap,
	map,
	catchError,
	of,
} from 'rxjs';
import * as fromPopularArticlesActions from './articles-carousel.actions';
import { getArticlesCarouselData } from './articles-carousel.selectors';
import { NavigationService } from '@uc/web/shared/feature/feature-navigation';

@Injectable()
export class StateArticlesCarouselEffect {
	constructor(
		private readonly actions$: Actions,
		private store: Store,
		private navigationSrv: NavigationService,
	) {}

	fetchAdviceArticles = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPopularArticlesActions.fetchArticlesCarouselData),
			withLatestFrom(this.store.select(getArticlesCarouselData)),
			distinctUntilChanged(),
			filter(([, articles]) => articles.length === 0),
			switchMap(([props]) =>
				from(this.navigationSrv.getFeaturedArticle(props.categoryId)).pipe(
					map((response) => {
						return fromPopularArticlesActions.updateArticlesCarouselData({
							articles: response.popular_article.reverse(),
						});
					}),
					catchError((error) =>
						of(
							fromPopularArticlesActions.loadArticlesCarouselError({
								error: error,
							}),
						),
					),
				),
			),
		),
	);
}
