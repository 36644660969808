import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SubnavAdviceComponent } from '../subnav-advice/subnav-advice.component';
import { SubnavClearingComponent } from '../subnav-clearing/subnav-clearing.component';
import { SubnavEventsComponent } from '../subnav-events/subnav-events.component';
import { SubnavGuidesComponent } from '../subnav-guides/subnav-guides.component';
import { SubnavRankingsComponent } from '../subnav-rankings/subnav-rankings.component';
import { SubnavStatementsComponent } from '../subnav-statements/subnav-statements.component';
import { SubNavCoursesComponent } from '../subnav-courses/subnav-courses.component';
import { SubNavUnisComponent } from '../subnav-unis/subnav-unis.component';

@Component({
	selector: 'uc-mobile-subnav-container',
	templateUrl: './mobile-subnav-container.component.html',
	imports: [
		SubnavAdviceComponent,
		SubnavClearingComponent,
		SubnavEventsComponent,
		SubnavGuidesComponent,
		SubnavRankingsComponent,
		SubnavStatementsComponent,
		SubNavCoursesComponent,
		SubNavUnisComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSubnavContainerComponent {
	section = input('');
}
