import { ChangeDetectionStrategy, Component } from '@angular/core';
import { statements } from '../../../static/subnav-statements.properties';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { NavCardLandscapeComponent } from '../../nav-card-landscape/nav-card-landscape.component';
import { NavBrowseAllComponent } from '../../nav-browse-all/nav-browse-all.component';

@Component({
	selector: 'uc-subnav-statements',
	templateUrl: './subnav-statements.component.html',
	imports: [NavLinkComponent, NavCardLandscapeComponent, NavBrowseAllComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavStatementsComponent {
	data = statements;
}
