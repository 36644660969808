import { createAction, props } from '@ngrx/store';
import { FeaturedUni } from '../../models/featured-university.models';

export const fetchFeaturedUni = createAction(
	'[FeaturedUniversity/API] Fetch Featured Uni',
);

export const updatedFeaturedUni = createAction(
	'[FeaturedUniversity] Update Featured Uni',
	props<{ featuredUni: FeaturedUni | null }>(),
);

export const loadFeaturedUniversityError = createAction(
	'[FeaturedUniversity] Load Featured University Error',
	props<{ error: any }>(),
);
