import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchAdviceArticles,
	fetchClearingArticles,
	fetchClearingUnis,
	fetchRecommendedUnis,
	fetchTickertape,
	loadNavError,
	updateAdviceArticles,
	updateClearingArticles,
	updateClearingUnis,
	updateRecommendedUnis,
	updateTickertape,
} from './navigation.actions';
import { Link, NavCard, Tickertape } from '../../models/navigation.models';

export const _STATE_NAVIGATION_FEATURE_KEY = 'stateNavigation';

export interface State {
	unis: NavCard[];
	articles: Link[];
	clearingUnis: NavCard[];
	clearingArticles: any[];
	tickertape: Tickertape[];
	error: string;
	loading: boolean;
}

export interface StateNavigationPartialState {
	readonly [_STATE_NAVIGATION_FEATURE_KEY]: State;
}

export const initialState: State = {
	unis: [],
	articles: [],
	clearingUnis: [],
	clearingArticles: [],
	tickertape: [],
	error: '',
	loading: false,
};

const stateNavReducer = createReducer(
	initialState,
	on(loadNavError, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	// Fetch State Reducer.
	on(
		fetchRecommendedUnis,
		fetchAdviceArticles,
		fetchClearingUnis,
		fetchClearingArticles,
		fetchTickertape,
		(state) => ({
			...state,
			error: '',
			loading: true,
		}),
	),

	//Unis
	on(updateRecommendedUnis, (state, { unis }) => ({
		...state,
		unis,
		error: '',
		loading: false,
	})),
	// Articles
	on(updateAdviceArticles, (state, { articles }) => ({
		...state,
		articles,
		error: '',
		loading: false,
	})),
	// Clearing Unis
	on(updateClearingUnis, (state, { clearingUnis }) => ({
		...state,
		clearingUnis,
		error: '',
		loading: false,
	})),
	// clearing articles
	on(updateClearingArticles, (state, { clearingArticles }) => ({
		...state,
		clearingArticles,
		error: '',
		loading: false,
	})),
	//tickertape
	on(updateTickertape, (state, { tickertape }) => ({
		...state,
		tickertape,
		error: '',
		loading: false,
	})),
);

export function navigationReducer(state: State | undefined, action: Action) {
	return stateNavReducer(state, action);
}
