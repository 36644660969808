@if (clearingArticles$ | async; as articles) {
	<section class="divider">
		<header class="flex items-center justify-between">
			<h2 class="text-xl font-bold leading-6 text-gray-900">
				Clearing {{ year() }} Advice Articles
			</h2>
			<!-- swiper nav buttons -->
			<div class="flex items-center gap-3">
				<p class="ml-8 whitespace-nowrap articles-pagination"></p>
				<div class="flex gap-3">
					<button
						type="button"
						data-toggle="tab"
						role="tab"
						aria-label="Prev"
						class="bg-white carousel-navigation swiper-button-prev swiper-button-prev-campus swiper-no-swiping articles-next"
					></button>
					<button
						type="button"
						data-toggle="tab"
						role="tab"
						aria-label="Next"
						class="bg-white carousel-navigation swiper-button-next swiper-button-next-campus swiper-no-swiping articles-prev"
					></button>
				</div>
			</div>
		</header>
		<div class="mb-8">
			<uc-description
				[truncateMobileOnly]="true"
				[description]="
					'Avoid university Clearing confusion with our helpful advice articles. Discover all there is to know about the application process, plus how to prepare for Clearing ' +
					year() +
					', what you will need, and important deadlines not to miss.'
				"
				[charLimit]="110"
				[shortLength]="110"
			>
			</uc-description>
		</div>

		<swiper-container #swiperRef init="false">
			@for (article of articles; track article.art_id) {
				<swiper-slide>
					<a
						[routerLink]="getAbsolutePath(article.url)"
						class="relative block w-full text-left h-44"
					>
						<img
							[ngSrc]="article.img_name | cdn"
							fill
							alt=""
							class="object-cover rounded-2xl lg:w-full"
						/>
					</a>

					<div class="pt-4 space-y-1 text-left">
						<a
							[routerLink]="[
								'/advice/',
								article.cat_name | categoryLink: 'advice',
							]"
							class="block font-medium text-sx text-uc-blue-600"
						>
							{{ article.cat_name.toLowerCase() | categoryName }}
						</a>

						<a
							class="block text-lg font-bold"
							[routerLink]="getAbsolutePath(article.url)"
						>
							{{ article?.title }}
						</a>
						<p class="pb-2 text-base text-gray-500">
							{{ article.date_posted | ucdate }}
							@if (article.reading_time > 0) {
								&middot; {{ article?.reading_time }} min read
							}
						</p>
					</div>
				</swiper-slide>
			}
		</swiper-container>
	</section>
}
