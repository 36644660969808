@if (paramKey()) {
	<a
		[routerLink]="basePath()"
		[queryParams]="getRouterParams(paramKey(), linkData().value)"
		(click)="closeNav(); navLinkClickSrv.onLinkClick(linkData().value, paramKey())"
		class="navlink-black"
		[ngClass]="{ 'flex flex-col': linkData().description }"
	>
		{{ linkData().title }}
		@if (linkData().description) {
			<span class="hidden nav-description md:block">
				{{ linkData().description }}
			</span>
		}
	</a>
}
@if (!paramKey()) {
	<a
		[routerLink]="linkData().value"
		(click)="closeNav(); navLinkClickSrv.onLinkClick(linkData().value)"
		class="navlink-black"
		[ngClass]="{ 'flex flex-col': linkData().description }"
	>
		<span class="flex items-center gap-2">
			{{ linkData().title }}
			@if (linkData().new) {
				<uc-new-badge-svg />
			}
		</span>
		@if (linkData().description) {
			<span class="hidden nav-description md:block">
				{{ linkData().description }}
			</span>
		}
	</a>
}
