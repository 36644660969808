import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavCard } from '../../models/navigation.models';
import { RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import { ImpressionDirective } from '@uc/web/core';
import { WriteReviewBtnComponent } from '@web/shared/ui';
import { ArrowRightV3SvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-nav-card-landscape',
	templateUrl: './nav-card-landscape.component.html',
	imports: [
		RouterLink,
		NgOptimizedImage,
		CdnPathPipe,
		ImpressionDirective,
		WriteReviewBtnComponent,
		ArrowRightV3SvgComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardLandscapeComponent extends BaseNavlinkComponent {
	data = input.required<NavCard>();
	linkClicked = output<void>();
	impressionMade = output<void>();

	onClick() {
		this.closeNav();
		this.linkClicked.emit();
	}

	onImpression() {
		this.impressionMade.emit();
	}
}
