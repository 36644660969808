<div class="border-t border-b">
	<div class="bg-gray-50 side-style">
		<a
			[routerLink]="path()"
			(click)="closeNav(); navLinkClickSrv.onLinkClick(path(), '')"
			class="justify-center py-5 lg:py-7 navlink-blue !text-base"
			>Browse All {{ page() }}</a
		>
	</div>
</div>
