import { gql } from 'apollo-angular';

const GET_FEATURED_UNI = gql`
	query getFeaturedUni {
		featuredUniversity {
			id
			name
			short_name
			slug
			logo
			saved
			order
			profile(product: UNDERGRADUATE) {
				external_url
				cover_image
			}
			address {
				region
			}
			stats {
				recommended_percentage
			}
		}
	}
`;

export { GET_FEATURED_UNI };
