import { Injectable } from '@angular/core';
import { FeaturedUni } from '../models/featured-university.models';
import { Apollo } from 'apollo-angular';
import { catchError, EMPTY, map } from 'rxjs';
import { GET_FEATURED_UNI } from './queries.graphql';

@Injectable({ providedIn: 'root' })
export class FeaturedUniversityApiService {
	constructor(private _apollo: Apollo) {}

	getFeaturedUniversity() {
		return this._apollo
			.query<{
				featuredUniversity: FeaturedUni;
			}>({
				query: GET_FEATURED_UNI,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.featuredUniversity;
				}),
			);
	}
}
