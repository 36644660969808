@if (section() === 'courses') {
	<uc-subnav-courses />
}
@if (section() === 'clearing') {
	<uc-subnav-clearing />
}
@if (section() === 'unis') {
	<uc-subnav-unis />
}
@if (section() === 'events') {
	<uc-subnav-events />
}
@if (section() === 'rankings') {
	<uc-subnav-rankings />
}
@if (section() === 'guides') {
	<uc-subnav-guides />
}
@if (section() === 'advice') {
	<uc-subnav-advice />
}
@if (section() === 'statements') {
	<uc-subnav-statements />
}
