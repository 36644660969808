import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-site-logo',
	templateUrl: './site-logo.component.html',
	imports: [RouterLink, NgOptimizedImage, CdnPathPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteLogoComponent extends BaseNavlinkComponent {
	isMobileShowing = input(false);
	expandedNav = input('');
	ucLogo = 'assets/img/logo.svg';
}
