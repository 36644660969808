<div ucDropdown class="relative flex items-center justify-center lg:block">
	<button
		(click)="closeNav()"
		class="text-sm bg-white rounded-full lg:flex dropbtn focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-500"
		data-cy="avatar-user-menu-icon-button"
		aria-haspopup="true"
	>
		<span class="sr-only">Open user menu</span>
		<!-- logged in: user avatar -->
		@if (userAuthenticated() && avatar()) {
			<span class="relative flex items-center flex-shrink-0 ml-4 lg:ml-0 h-9 w-9">
				<img
					class="flex items-center"
					alt="User Avatar"
					[ngSrc]="avatar() | cdn"
					fill
				/>
			</span>
		}
		<!-- logged in: client logo -->
		@if (clientAuthenticated() && logo()) {
			<span class="relative flex items-center flex-shrink-0 ml-4 lg:ml-0 h-9 w-9">
				<img
					class="flex items-center"
					alt="Uni Logo"
					[ngSrc]="logo() | cdn"
					fill
				/>
			</span>
		}

		<!-- no avatar: use SVG -->
		@if (!avatar() && !logo()) {
			<span class="flex items-center flex-shrink-0 ml-4 lg:ml-0">
				<uc-user-icon-svg />
			</span>
		}
	</button>
	<!-- not logged in -->
	@if (!userAuthenticated() && !clientAuthenticated()) {
		<div
			class="absolute right-0 w-40 bg-white rounded-md shadow-lg dropdown-content top-[3.55rem] lg:rounded-b-md lg:rounded-t-none ring-1 ring-black ring-opacity-5"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="user-menu"
		>
			<button
				(click)="showModalPanel(); onLoginClick()"
				class="block w-full px-4 py-2 text-left text-gray-700 cursor-pointer text-md hover:bg-gray-100"
				role="menuitem"
				data-cy="avatar-user-menu-login"
			>
				Sign In
			</button>
			<button
				(click)="onRegisterClick()"
				routerLink="/register"
				class="block w-full px-4 py-2 text-left text-gray-700 cursor-pointer text-md hover:bg-gray-100 hover:rounded-b-md"
				role="menuitem"
				data-cy="avatar-user-menu-register"
			>
				Register
			</button>
		</div>
	}
	<!-- logged in regular user -->
	@if (userAuthenticated()) {
		<div
			class="dropdown-content absolute right-0 top-14 -mt-0.5 w-40 rounded-md lg:rounded-b-md lg:rounded-t-none shadow-lg bg-white ring-1 ring-black ring-opacity-5"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="user-menu"
		>
			<button
				routerLink="/user-profile"
				class="block w-full px-4 py-2 text-left text-gray-700 cursor-pointer text-md hover:bg-gray-100"
				role="menuitem"
			>
				Edit Profile
			</button>
			<button
				class="block w-full px-4 py-2 text-left text-gray-700 cursor-pointer text-md hover:bg-gray-100 hover:rounded-b-md"
				role="menuitem"
				(click)="signOut()"
			>
				Sign Out
			</button>
		</div>
	}
	<!-- logged in university options -->
	@if (clientAuthenticated()) {
		<div
			class="dropdown-content absolute right-0 top-14 -mt-0.5 w-40 rounded-md lg:rounded-b-md lg:rounded-t-none shadow-lg bg-white ring-1 ring-black ring-opacity-5"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="user-menu"
		>
			<a
				href="/dashboard"
				class="block px-4 py-2 text-gray-700 cursor-pointer text-md hover:bg-gray-100"
				role="menuitem"
				>Dashboard</a
			>
			<a
				routerLink="/contact"
				class="block px-4 py-2 text-gray-700 cursor-pointer text-md hover:bg-gray-100"
				role="menuitem"
				>Contact</a
			>
			<!-- todo: specific cookies for universities -->
			<button
				class="block w-full px-4 py-2 text-left text-gray-700 cursor-pointer rounded-b-md text-md hover:bg-gray-100"
				role="menuitem"
				(click)="signOut()"
			>
				Sign out
			</button>
		</div>
	}
</div>
