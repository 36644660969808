import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchFeaturedUni } from './data-access/+state/featured-university.actions';
import { getStateFeaturedUni } from './data-access/+state/featured-university.selectors';
import { ImpressionDirective, UniversityGTMService } from '@uc/web/core';
import { ProductEnum } from '@uc/web/shared/data-models';
import { AsyncPipe } from '@angular/common';
import {
	EngageCardCtaComponent,
	RequestBtnComponent,
	VisitWebsiteBtnComponent,
	WriteReviewBtnComponent,
} from '@web/shared/ui';

@Component({
	selector: 'uc-featured-university',
	templateUrl: './featured-university.component.html',
	imports: [
		AsyncPipe,
		ImpressionDirective,
		EngageCardCtaComponent,
		WriteReviewBtnComponent,
		RequestBtnComponent,
		VisitWebsiteBtnComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedUniversityComponent implements OnInit {
	featuredUni$ = this._store.select(getStateFeaturedUni);

	constructor(
		private _store: Store,
		private _uniGTMSrv: UniversityGTMService,
	) {}

	ngOnInit(): void {
		this._store.dispatch(fetchFeaturedUni());
	}

	onUniImpression(
		impression: boolean,
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
	) {
		if (impression) {
			this._uniGTMSrv.uniImpression(
				uniId,
				uniName,
				ugSearchOrderPosition,
				null,
				ProductEnum.Undergraduate,
			);
		}
	}

	onUniProfileClick(
		uniId: number,
		uniName: string,
		ugSearchOrderPosition: number | null,
	) {
		this._uniGTMSrv.uniProfileClick(
			uniId,
			uniName,
			ugSearchOrderPosition,
			null,
			ProductEnum.Undergraduate,
		);
	}
}
