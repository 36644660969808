import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavLinkClickService } from '@uc/web/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'uc-nav-browse-all',
	templateUrl: './nav-browse-all.component.html',
	imports: [RouterLink],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBrowseAllComponent extends BaseNavlinkComponent {
	path = input('');
	page = input('');

	constructor(public navLinkClickSrv: NavLinkClickService) {
		super();
	}
}
