import { createAction, props } from '@ngrx/store';

export const loadArticlesCarouselError = createAction(
	'[Articles Carousel] load error',
	props<{ error: any }>(),
);

//featured articles
export const fetchArticlesCarouselData = createAction(
	'[Articles Carousel] fetch articles',
	props<{ categoryId: number }>(),
);

export const updateArticlesCarouselData = createAction(
	'[Articles Carousel] update articles',
	props<{ articles: any }>(),
);
