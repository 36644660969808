import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { NavCardLandscapeComponent } from '../../nav-card-landscape/nav-card-landscape.component';
import { COURSES_NAVIGATION } from '../../../static/subnav-courses.properties';

@Component({
	selector: 'uc-subnav-courses',
	templateUrl: './subnav-courses.component.html',
	standalone: true,
	imports: [NavLinkComponent, NavCardLandscapeComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavCoursesComponent {
	data = { ...COURSES_NAVIGATION };
}
