import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	withLatestFrom,
	distinctUntilChanged,
	filter,
	switchMap,
	map,
	catchError,
	of,
} from 'rxjs';

import { FeaturedUniversityApiService } from '../featured-university-api.service';
import { getStateFeaturedUni } from './featured-university.selectors';
import {
	fetchFeaturedUni,
	loadFeaturedUniversityError,
	updatedFeaturedUni,
} from './featured-university.actions';

@Injectable()
export class StateFeaturedUniversityEffects {
	constructor(
		private readonly actions$: Actions,
		private store: Store,
		private featuredUniversityApiSrv: FeaturedUniversityApiService,
	) {}

	fetchFeaturedUni = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchFeaturedUni),
			withLatestFrom(this.store.select(getStateFeaturedUni)),
			distinctUntilChanged(),
			filter(([, featured]) => !featured),
			switchMap(() => {
				return this.featuredUniversityApiSrv.getFeaturedUniversity();
			}),
			map((uni) =>
				updatedFeaturedUni({
					featuredUni: uni,
				}),
			),
			catchError((error) => of(loadFeaturedUniversityError(error))),
		),
	);
}
