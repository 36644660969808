import { Action, createReducer, on } from '@ngrx/store';
import * as fromPopularArticlesActions from './articles-carousel.actions';
import { ArticleSummary } from '@uc/web/shared/data-models';

export const _STATE_ARTICLES_CAROUSEL_FEATURE_KEY = 'stateArticlesCarousel';

export interface State {
	articles: ArticleSummary[];
	loading: boolean;
	error: any;
}

export interface StatePopularClearingArticlesState {
	readonly [_STATE_ARTICLES_CAROUSEL_FEATURE_KEY]: State;
}

export const initialState: State = {
	articles: [],
	loading: false,
	error: '',
};

const reducer = createReducer(
	initialState,
	on(fromPopularArticlesActions.loadArticlesCarouselError, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),
	on(fromPopularArticlesActions.fetchArticlesCarouselData, (state) => ({
		...state,
		error: '',
		loading: true,
	})),
	on(fromPopularArticlesActions.updateArticlesCarouselData, (state, { articles }) => ({
		...state,
		articles,
		error: '',
		loading: false,
	})),
);

export function articlesCarouselReducer(state: State | undefined, action: Action) {
	return reducer(state, action);
}
