<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-2 lg:container">
		<!-- left panel -->
		<div class="lg:pt-8 lg:pb-11 lg:grid lg:grid-cols-2 lg:gap-9">
			<div>
				<p class="subnav-heading">{{ data.sublinks[0].heading }}</p>
				<div class="grid grid-cols-1 subnav-link-padding">
					@for (link of data.sublinks[0].links; track link) {
						<uc-nav-link [basePath]="link.value" [linkData]="link" />
					}
				</div>
			</div>
			<div>
				<p class="subnav-heading">{{ data.sublinks[1].heading }}</p>
				<div class="grid grid-cols-1 subnav-link-padding">
					@for (link of data.sublinks[1].links; track link) {
						<uc-nav-link
							[paramKey]="data.sublinks[1].param"
							[basePath]="data.browse"
							[linkData]="link"
						/>
					}
				</div>
			</div>
		</div>

		<!-- divider on mobile -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		@if (unis$ | async; as recommendedUnis) {
			<div
				data-cy="subnav-unis-recommended-unis"
				class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:pb-11 bg-gray-50 side-style"
			>
				<p class="subnav-heading">Recommended Universities</p>
				@for (card of recommendedUnis; track card) {
					<uc-nav-card-landscape
						[data]="card"
						(linkClicked)="onClick(card)"
						(impressionMade)="onImpression(card)"
					/>
				}
			</div>
		}
	</div>
</div>
