import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchPrimeProviders } from './data-access/+state/prime-providers.actions';
import { getPrimeProviders } from './data-access/+state/prime-providers.selectors';
import { PrimeProviderCardComponent } from './components/prime-provider-card/prime-provider-card.component';
import { AsyncPipe } from '@angular/common';
import { HeadingComponent } from '@uc/shared/ui';

@Component({
	selector: 'uc-prime-providers',
	templateUrl: './prime-providers.component.html',
	imports: [AsyncPipe, HeadingComponent, PrimeProviderCardComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrimeProvidersComponent implements OnInit {
	heading = input('Top Clearing Universities');

	providers$ = this._store.select(getPrimeProviders);

	description =
		'Looking for a Clearing course but unsure how to start your search? Take a look at these UK universities that have Clearing courses available. If a uni catches your eye, reach out to find out more.';

	constructor(private _store: Store) {}

	ngOnInit(): void {
		this._store.dispatch(fetchPrimeProviders());
	}
}
