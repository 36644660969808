import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchFeaturedUni,
	updatedFeaturedUni,
	loadFeaturedUniversityError,
} from './featured-university.actions';
import { FeaturedUni } from '../../models/featured-university.models';
import { updateSavedInStore } from '@web/shared/ui';

export const _FEATURED_UNIVERSITY_FEATURE_KEY = 'featuredUniversity';

export interface State {
	featuredUni: FeaturedUni | null;
	loading: boolean;
	error: any;
}

export interface StateNavigationPartialState {
	readonly [_FEATURED_UNIVERSITY_FEATURE_KEY]: State;
}

export const initialState: State = {
	featuredUni: null,
	loading: false,
	error: '',
};

const stateFeatureUniversityReducer = createReducer(
	initialState,
	//initiate action with effect
	on(fetchFeaturedUni, (state) => ({
		...state,
		error: '',
		loading: true,
	})),
	//uni
	on(updatedFeaturedUni, (state, { featuredUni }) => ({
		...state,
		featuredUni,
		error: '',
		loading: false,
	})),
	on(loadFeaturedUniversityError, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),
	// bookmark
	on(updateSavedInStore, (state, { uniId }) => ({
		...state,
		featuredUni: updateSavedProperty(state.featuredUni, uniId),
		loaded: true,
		error: null,
	})),
);

export function featureUniversityReducer(state: State | undefined, action: Action) {
	return stateFeatureUniversityReducer(state, action);
}

function updateSavedProperty(featuredUni: FeaturedUni | null, uniId: number) {
	if (featuredUni?.id === uniId) {
		return {
			...featuredUni,
			saved: !featuredUni.saved,
		};
	}

	return featuredUni;
}
