import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LoginGTMService } from '@uc/web/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import {
	DegreeQuizGTMService,
	DownloadAppGTMService,
	ProspectusGTMService,
	RegisterGTMService,
} from '@uc/web/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'uc-secondary-nav-desktop',
	templateUrl: './secondary-nav-desktop.component.html',
	imports: [RouterLink],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryNavDesktopComponent extends BaseNavlinkComponent {
	userAuthenticated = input<boolean>();
	clientAuthenticated = input<boolean>();

	private readonly _componentName = 'Nav';

	constructor(
		private _loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
		private _prospectusGTMService: ProspectusGTMService,
		private _downloadAppGTMService: DownloadAppGTMService,
		private _degreeQuizGTMSrv: DegreeQuizGTMService,
	) {
		super();
	}

	onLoginClick = () => this._loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
	onProspectusClick = () =>
		this._prospectusGTMService.getProspectusClick(this._componentName, null);
	onDownloadAppClick = () => this._downloadAppGTMService.downloadAppClick();
	onQuizButtonClick = () => this._degreeQuizGTMSrv.onQuizButtonClick('default');
}
