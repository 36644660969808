import { ChangeDetectionStrategy, Component } from '@angular/core';
import { guides } from '../../../static/subnav-guides.properties';
import { NavLinkComponent } from '../../nav-link/nav-link.component';
import { NavBrowseAllComponent } from '../../nav-browse-all/nav-browse-all.component';

@Component({
	selector: 'uc-subnav-guides',
	templateUrl: './subnav-guides.component.html',
	imports: [NavLinkComponent, NavBrowseAllComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavGuidesComponent {
	data = guides;
}
