import { AsyncPipe, isPlatformBrowser, NgClass } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { tap } from 'rxjs';
import { configureSource, Environment } from '@uc/utilities';
import { ImpressionDirective, TickertapeGTMService } from '@uc/web/core';
import { Tickertape } from '../../models/navigation.models';
import { Store } from '@ngrx/store';
import { fetchTickertape } from '../../data-access/+store/navigation.actions';
import { getStateNavTickertape } from '../../data-access/+store/navigation.selectors';
import { ClearingVisitWebsiteBtnApiService } from '@uc/web/shared/data-access';
import { LeftArrowSvgComponent, RightArrowSvgComponent } from '@uc/shared/svg';
import { RouterLink } from '@angular/router';
@Component({
	selector: 'uc-tickertape',
	templateUrl: './tickertape.component.html',
	imports: [
		AsyncPipe,
		NgClass,
		RouterLink,
		ImpressionDirective,
		LeftArrowSvgComponent,
		RightArrowSvgComponent,
	],
})
export class TickertapeComponent implements OnInit, OnDestroy {
	unisMaxIndex!: number;
	displayedUniIndex = 0;
	interval!: ReturnType<typeof setInterval>;

	tickertape$ = this._store.select(getStateNavTickertape).pipe(
		tap((tickertape) => {
			if (!tickertape) return;
			this.unisMaxIndex = tickertape.length - 1;
		}),
	);

	clearingEnabled!: boolean;

	private _platformId: string;

	constructor(
		@Inject(PLATFORM_ID) _platformId: string,
		@Inject('environment') _environment: Environment,
		private _store: Store,
		private _clearingVisitWebsiteBtnSrv: ClearingVisitWebsiteBtnApiService,
		private _tickertapeGtmSrv: TickertapeGTMService,
	) {
		this._platformId = _platformId;
		this.clearingEnabled = _environment.clearingEnabled;
	}

	ngOnInit(): void {
		this._store.dispatch(fetchTickertape());
		this.slideShow();
	}

	ngOnDestroy(): void {
		if (this.interval) clearInterval(this.interval);
	}

	onClickLeft(): void {
		if (this.displayedUniIndex >= 1) {
			this.displayedUniIndex--;
		} else if (this.displayedUniIndex === 0) {
			this.displayedUniIndex = this.unisMaxIndex;
		}

		if (this.interval) clearInterval(this.interval);
		this.slideShow();
	}

	onClickRight(): void {
		if (this.unisMaxIndex > this.displayedUniIndex) {
			this.displayedUniIndex++;
		} else if (this.unisMaxIndex === this.displayedUniIndex) {
			this.displayedUniIndex = 0;
		}

		if (this.interval) clearInterval(this.interval);
		this.slideShow();
	}

	slideShow(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.interval = setInterval(() => {
				this.unisMaxIndex > this.displayedUniIndex
					? this.displayedUniIndex++
					: (this.displayedUniIndex = 0);
			}, 10000);
		}
	}

	trackWebsiteClick(uni: Tickertape) {
		const { id, name } = uni.university;
		const uniName = configureSource(name, 'uni');

		if (uni.is_tracked) {
			this._clearingVisitWebsiteBtnSrv.clearingVisitWebsiteClick(id, uniName);
		}
	}

	onTickertapeClick(uniId: number) {
		this._tickertapeGtmSrv.click(uniId);
	}

	onTickertapeImpression(impression: number) {
		if (impression) {
			this._tickertapeGtmSrv.impression(impression);
		}
	}
}
