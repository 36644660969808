import { createFeatureSelector, createSelector } from '@ngrx/store';
import { _STATE_ARTICLES_CAROUSEL_FEATURE_KEY, State } from './articles-carousel.reducer';

export const articlesCarouselState = createFeatureSelector<State>(
	_STATE_ARTICLES_CAROUSEL_FEATURE_KEY,
);

export const getArticlesCarouselData = createSelector(
	articlesCarouselState,
	(state: State) => state.articles,
);
