import { AsyncPipe, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import {
	Component,
	OnInit,
	Inject,
	PLATFORM_ID,
	AfterViewInit,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { Observable, take, tap } from 'rxjs';
import { PrimeProvider } from '../../models/prime-providers.models';
import { PrimeProviderPopupService } from './prime-provider-popup.service';
import { PrimePopupGTMService, UniversityGTMService } from '@uc/web/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ProductEnum, ScreenWidth } from '@uc/web/shared/data-models';
import { ModalService } from '@uc/shared/ui';
import { AbbreviateButtonTextPipe, CdnPathPipe, EntitiesPipe } from '@uc/utilities';
import { CloseModalSvgComponent, GreenFlameSvgComponent } from '@uc/shared/svg';
import { RouterLink } from '@angular/router';
import {
	CallUniBtnComponent,
	ClearingVisitWebsiteBtnComponent,
	WriteReviewBtnComponent,
} from '@web/shared/ui';
import { PrimeProviderPopupMobileComponent } from '../prime-provider-popup-mobile/prime-provider-popup-mobile.component';

@Component({
	selector: 'uc-prime-provider-popup',
	templateUrl: './prime-provider-popup.component.html',
	imports: [
		AsyncPipe,
		NgOptimizedImage,
		RouterLink,
		CdnPathPipe,
		AbbreviateButtonTextPipe,
		EntitiesPipe,
		GreenFlameSvgComponent,
		CloseModalSvgComponent,
		WriteReviewBtnComponent,
		CallUniBtnComponent,
		ClearingVisitWebsiteBtnComponent,
		PrimeProviderPopupMobileComponent,
	],
})
export class PrimeProviderPopupComponent implements OnInit, AfterViewInit {
	@ViewChild('article') article!: ElementRef;
	uni$!: Observable<PrimeProvider>;
	currentWindowWidth!: number;
	isMobile = this._breakpointObserver.isMatched(`(max-width: ${ScreenWidth.SM - 1}px)`);
	isDesktop = this._breakpointObserver.isMatched(`(min-width: ${ScreenWidth.LG}px)`);
	private platform: string;

	constructor(
		@Inject(PLATFORM_ID) _platformId: string,
		private _breakpointObserver: BreakpointObserver,
		private _pppSrv: PrimeProviderPopupService,
		private _modalSrv: ModalService,
		private _pppGTMSrv: PrimePopupGTMService,
		private _uniGTMSrv: UniversityGTMService,
	) {
		this.platform = _platformId;
	}

	ngOnInit(): void {
		this.uni$ = this._pppSrv.university$.pipe(
			take(1),
			tap((uni) => this._pppGTMSrv.impression(uni.id)),
		);
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platform)) {
			const articleHeight = this.article.nativeElement.offsetHeight;
			const screenHeight = window.innerHeight;
			if (articleHeight < screenHeight) {
				this.article.nativeElement.style.height = `${articleHeight}px`;
			} else {
				this.article.nativeElement.style.height = '100vh';
			}
		}
	}

	closeModal() {
		this._modalSrv.close();
	}

	onUniProfileClick(uni: PrimeProvider) {
		this._uniGTMSrv.uniProfileClick(
			uni.id,
			uni.name,
			null,
			uni.order,
			ProductEnum.Clearing,
		);
	}
}
