import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, _FEATURED_UNIVERSITY_FEATURE_KEY } from './featured-university.reducers';

export const getStateFeaturedUniversityState = createFeatureSelector<State>(
	_FEATURED_UNIVERSITY_FEATURE_KEY,
);

export const getStateFeaturedUni = createSelector(
	getStateFeaturedUniversityState,
	(state: State) => state.featuredUni,
);
