import { Component, input } from '@angular/core';
import { ModalService } from '@uc/shared/ui';
import { CdnPathPipe } from '@uc/utilities';
import { UniversityGTMService } from '@uc/web/core';
import { Image, ProductEnum } from '@uc/web/shared/data-models';
import {
	CallUniBtnComponent,
	ClearingVisitWebsiteBtnComponent,
	SecondaryBlueBtnComponent,
} from '@web/shared/ui';

@Component({
	selector: 'uc-prime-provider-popup-mobile',
	templateUrl: './prime-provider-popup-mobile.component.html',
	imports: [
		CdnPathPipe,
		CallUniBtnComponent,
		ClearingVisitWebsiteBtnComponent,
		SecondaryBlueBtnComponent,
	],
})
export class PrimeProviderPopupMobileComponent {
	uniName = input.required<string>();
	uniId = input.required<number>();
	imageCover = input.required<string>();
	externalUrl = input.required<string>();
	uniSlug = input.required<string>();
	gallery = input<Image[]>([]);
	cleSearchOrderPosition = input.required<number>();
	phoneNumber = input<string>('');
	privacyPolicyUrl = input<string | null>('');

	constructor(
		private _modalSrv: ModalService,
		private _uniGTMSrv: UniversityGTMService,
	) {}

	onCloseModal() {
		this._modalSrv.close();
	}

	onUniProfileClick() {
		this._uniGTMSrv.uniProfileClick(
			this.uniId(),
			this.uniName(),
			null,
			this.cleSearchOrderPosition(),
			ProductEnum.Clearing,
		);
	}
}
